import { inject } from "@angular/core";
import { CanActivateFn, Router, ActivatedRouteSnapshot } from "@angular/router";
import { firstValueFrom } from "rxjs";
import { AuthService } from "./auth.service";
import { SharedGuardService } from "./shared-guard.service";

export const canAccess: CanActivateFn = async (route: ActivatedRouteSnapshot) => {
  const router = inject(Router);
  const authService = inject(AuthService);
  const sharedGuardService = inject(SharedGuardService);
  const isLoggedIn = await firstValueFrom(authService.getLoggedInSubject());

  if (!isLoggedIn) {
    return router.parseUrl(`/login?redirectUrl=${window.location.pathname}`);
  }

  const user = await sharedGuardService.hasUser();

  if (!user) {
    return router.parseUrl(`/login?redirectUrl=${window.location.pathname}`);
  }

  if (user.consentTypes.length > 0) {
    return router.parseUrl("/consent");
  }

  if (route.url[0]?.path === "profile" || route.url[0]?.path === "delete") {
    return true;
  }

  const requiresVerification = await sharedGuardService.requiresVerification();
  if (!requiresVerification) {
    return router.parseUrl("/verification");
  }

  const setupCompleted = await sharedGuardService.isSetupCompleted();
  if (!setupCompleted) {
    return router.parseUrl("/profile-setup");
  }

  return true;
};
